import React, { useState } from "react";
import ReactDOM from 'react-dom';
import closeIcon from './CloseVector.svg'
import infoIcon from './InfoVector.svg'

const Alert = ({ message, handleClose }) => {
    return (
      <div className="alert">
      <div className="info-icon">
        <img src={infoIcon} alt="Info" />
      </div>
      <div className="align-left" dangerouslySetInnerHTML={{ __html: message }}>
      </div>
      <div>
        <img src={closeIcon} alt="Close" onClick={handleClose} />
      </div>
    </div>
  );
};

export default Alert;
