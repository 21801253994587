import { CardCategory } from "../../containers/Card/CardCategory";
import downloadIcon from "./DownloadVector.svg";

export const ModalBody = ({ data: children }) => {
  return (
    <div className="fields-container">
      {/*Modal Top with Header and Description*/}
      <div className="modal-top-container">
        <div className="modal-title">
          <h1>{children["Project Name"]}</h1>
        </div>
        <div className="modal-desc">{children["Description"]}</div>
      </div>

      {/*Modal Image and Credits*/}
      <div className="modal-img-container">
        <div className="modal-img">
          <img
            src={
              children["Images"] === undefined
                ? ""
                : children["Images"][0]["url"]
            }
            alt="Fixed Source"
          ></img>
        </div>
        <div className="modal-img-bottom">
          <div className="modal-madeby">
            <div className="modal-madeby-title">MADE BY</div>
            {children["Made By"].map((m) => (
              <div className="modal-madeby-name">
                {children["Made By"].indexOf(m) ===
                children["Made By"].length - 1
                  ? m
                  : m + ", "}
              </div>
            ))}
          </div>
          {children["Hospital"] && (
            <div className="modal-hospital">
              <div className="modal-hospital-title">HOSPITAL</div>
              {children["Hospital"].map((m) => (
                <div className="modal-hospital-name">{m}</div>
              ))}
            </div>
          )}
        </div>
      </div>
      {/*Modal Download and Categories*/}
      <div className="category-download-container">
        <button
          className={children["Link to Guides"] ? "modal-download" : "hide"}
          onClick={() => window.open(children["Link to Guides"], "_blank")}
        >
          <div className="button-holder">
            <div className="icon">
              <img
                src={downloadIcon}
                alt="Download"
                className="download-icon"
              />
            </div>
            <div className="text">Link to Guides</div>
          </div>
        </button>
        <div className="category-container">
          <CardCategory categories={children["Category"]} />
        </div>
      </div>

      <hr />
      <hr />

      <div className="problem-container">
        <div className="problem-wrapper">
          <div className="problem-title">PROBLEM</div>
          <div className="problem-desc">{children["Problem"]}</div>
        </div>
      </div>

      <div className="solution-container">
        <div className="solution-wrapper">
          <div className="solution-title">SOLUTION</div>
          <div className="solution-desc">{children["Solution"]}</div>
        </div>
      </div>

      {children["Source"] && (
        <div className="source-container">
          <div className="source-title">Sources</div>
          <div className="source-desc">
            {children["Source"].split("\n").map(
              (s) =>
                s && (
                  <div>
                    <b style={{ "margin-right": "10px" }}>&rarr;</b>
                    {s}
                  </div>
                )
            )}
          </div>
        </div>
      )}

      {children["Notes"] && (
        <div className="notes-container">
          <div className="notes-wrapper">
            <div className="notes-title">NOTES</div>
            <div className="notes-desc">{children["Notes"]}</div>
          </div>
        </div>
      )}

      {children["Disclaimer"] && (
        <div className="disclaimer-container">
          <div className="disclaimer-wrapper">
            <div className="disclaimer-title">DISCLAIMER</div>
            <div className="disclaimer-desc">{children["Disclaimer"]}</div>
          </div>
        </div>
      )}
    </div>
  );
};
