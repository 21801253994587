import "./App.scss";
import { Route, Routes, Navigate } from "react-router-dom";
import { Dashboard } from "./containers/Dashboard";
import SharedLayout from "./containers/Shared/SharedLayout";
import { AirTableProvider } from "./contexts/AirTableContext";
import {Helmet} from "react-helmet";

function App() {
  return (
    <AirTableProvider>
        <Helmet
            script={[{
                type: 'text/javascript',
                innerHTML: 'window.addEventListener(\'resize\', (event) => {\n' +
                    '        const header = document.querySelector(\'.header\');\n' +
                    '        const footer = document.querySelector(\'.footer\');\n' +
                    '        const content = document.querySelector(\'.content\');\n' +
                    '        console.log(header.offsetHeight);\n' +
                    '        content.style.top = header.offsetHeight + \'px\';\n' +
                    '        content.style.bottom = footer.offsetHeight + \'px\';\n' +
                    '    });'
            }, {
                type: 'text/javascript',
                innerHTML: 'window.addEventListener(\'load\', (event) => {\n' +
                    '        const header = document.querySelector(\'.header\');\n' +
                    '        const footer = document.querySelector(\'.footer\');\n' +
                    '        const content = document.querySelector(\'.content\');\n' +
                    '        console.log(header.offsetHeight);\n' +
                    '        content.style.top = header.offsetHeight + \'px\';\n' +
                    '        content.style.bottom = footer.offsetHeight + \'px\';\n' +
                    '    });'
            }, {
                type: 'text/javascript',
                innerHTML: 'window.addEventListener(\'click\', (event) => {\n' +
                    '        const header = document.querySelector(\'.header\');\n' +
                    '        const footer = document.querySelector(\'.footer\');\n' +
                    '        const content = document.querySelector(\'.content\');\n' +
                    '        console.log(header.offsetHeight);\n' +
                    '        content.style.top = header.offsetHeight + \'px\';\n' +
                    '        content.style.bottom = footer.offsetHeight + \'px\';\n' +
                    '    });'
            }]}
        >
            <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate"/>
            <meta http-equiv="Pragma" content="no-cache"/>
            <meta http-equiv="Expires" content="0"/>
        </Helmet>
      <SharedLayout>
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="*" element={<Navigate to="/dashboard" />} />
        </Routes>
      </SharedLayout>
    </AirTableProvider>
  );
}
export default App;
