export const getColor = (categoryType) => {
  if (categoryType === "Personal Protection Equipment") {
    return "#ceedfa";
  } else if (categoryType === "General") {
    return "#e2d5f7";
  } else if (categoryType === "Neonatal Care") {
    return "#ffeab6";
  } else if (categoryType === "Occupational Therapy") {
    return "#c7f0e4";
  } else if (categoryType === "Critical care") {
    return "#fcccf0";
  } else if (categoryType === "Education") {
    return "#ffd4df";
  } else if (categoryType === "Rehabilitation") {
    return "#ceedfa";
  } else if (categoryType === "Assistive Technology") {
    return "#cef5d2";
  } else if (categoryType === "Emergency Medicine") {
    return "#fcccf0";
  } else if (categoryType === "Urology") {
    return "#cce0fe";
  } else if (categoryType === "Admin") {
    return "#ffdccc";
  } else if (categoryType === "Communication") {
    return "#cce0fe";
  } else if (categoryType === "PPE") {
    return "#ceedfa";
  } else if (categoryType === "Pediatrics") {
    return "#cef5d2";
  }

  // Default
  return "white";
};

export const Category = ({ category }) => (
  <div
    className="category"
    style={{ backgroundColor: getColor(category) }}
  >
    <p> {category} </p>
  </div>
);

export const CardCategory = ({ categories }) => {
  return (
    <div className="cardCategory">
      {categories.map((category, index) => (
        <div key={index}>
          <Category category={category} index={index} />
        </div>
      ))}
    </div>
  );
};
