import { CardCategory } from "./CardCategory";

export const Card = ({record, onClick}) => {
  return (
    <div className="card-wrapper" onClick={onClick}>
      <div className="overlay-layer"> </div>
      <div className="card" >
        <img src= {record["Images"] === undefined ? "" : record["Images"][0]["thumbnails"]["large"]["url"]} alt="Fixed Source"></img>
        <div className="details">
          <h2> { record["Project Name"] } </h2>
          <h3> Description </h3>
          <p> { record["Description"] } </p>
          <h3> Category </h3>
          <CardCategory categories={record["Category"]}/>
          <h3> Made By </h3>
          <div className="madeBy">
            {record["Made By"].map((m) => (
              <div className="info">
                <p> {m} </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
