import React, { useEffect, useState } from "react";
import { ModalBody } from "./ModalBody";
import arrowEnabledIcon from "./ArrowEnabledVector.svg";
import arrowDisabledIcon from "./ArrowDisabledVector.svg";
import closeIcon from "./CloseVector.svg";

export const Modal = ({
  isOpen,
  onClose,
  children,
  onNext,
  onPrev,
  isFirst,
  isLast,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  function handleBackgroundClick(event) {
    if (event.target === event.currentTarget) {
      onClose();
    }
  }

  function handleKeyDown(event) {
    if (event.key === "Escape") {
      onClose();
    }
  }

  function handleClose() {
    setIsModalOpen(false);
    onClose();
  }

  useEffect(() => setIsModalOpen(isOpen), [isOpen]);
  return (
    isModalOpen && (
      <div
        className="modal"
        onClick={handleBackgroundClick}
        onKeyDown={handleKeyDown}
      >
        <div className="modal-wrapper">
          <div className="grey-bar" />
          <div className="modal-content">
            {/* Modal Header Buttons */}
            <div className="modal-buttons">
              <button
                className={
                  "modal-prev" + (isFirst ? " disabled-button-border" : "")
                }
                onClick={onPrev}
                disabled={isFirst}
              >
                {!isFirst ? (
                  <img
                    src={arrowEnabledIcon}
                    alt="arrowUp"
                    className="arrow-up-icon-enabled"
                  />
                ) : (
                  <img
                    src={arrowDisabledIcon}
                    alt="arrowUp"
                    className="arrow-up-icon-disabled"
                  />
                )}
              </button>
              <button
                className={
                  "modal-next" + (isLast ? " disabled-button-border" : "")
                }
                onClick={onNext}
                disabled={isLast}
              >
                {!isLast ? (
                  <img
                    src={arrowEnabledIcon}
                    alt="arrowDown"
                    className="arrow-down-icon-enabled"
                  />
                ) : (
                  <img
                    src={arrowDisabledIcon}
                    alt="arrowDown"
                    className="arrow-down-icon-disabled"
                  />
                )}
              </button>
              <div className="modal-close-container">
                <button className="modal-close" onClick={handleClose}>
                  <img src={closeIcon} alt="close" className="" />
                </button>
              </div>
            </div>
            <ModalBody data={children} />
          </div>
        </div>
      </div>
    )
  );
};
