import React, { useState, useEffect } from "react";

import { Card } from "./Card";

import { Modal } from "../../components/Modal";

import { useAirTableContext } from "../../contexts/AirTableContext";

export const CardContainer = () => {
  const {filteredRecords: originalRecords} = useAirTableContext();

  const [augmentedRecords, setAugmentedRecords] = useState([]);

  const augmentRecords = () => {
    const augmentedSampleRecords = originalRecords.map((record) => ({
      ...record,
      isOpen: false,
    }));
    setAugmentedRecords(augmentedSampleRecords);
  };

  useEffect(augmentRecords, [originalRecords]);

  const handleOpenModal = (index) => {
    const updateRecords = (prevRecords) => {
      const updatedModal = { ...prevRecords[index], isOpen: true };
      const updatedRecords = [
        ...prevRecords.slice(0, index),
        updatedModal,
        ...prevRecords.slice(index + 1),
      ];
      return updatedRecords;
    };
    setAugmentedRecords(updateRecords);
  };

  const handleCloseModal = (index) => {
    const updateRecords = (prevRecords) => {
      const updatedModal = { ...prevRecords[index], isOpen: false };
      const updatedRecords = [
        ...prevRecords.slice(0, index),
        updatedModal,
        ...prevRecords.slice(index + 1),
      ];
      return updatedRecords;
    };
    setAugmentedRecords(updateRecords);
  };

  const handleOnNext = (index) => {
    handleCloseModal(index);
    handleOpenModal(index + 1);
  };

  const handleOnPrev = (index) => {
    handleCloseModal(index);
    handleOpenModal(index - 1);
  };
  
  
  const splitList = (records, n) => {
    var columnRecords = [];
    for (let i = 0; i < n; i++) {
      columnRecords.push([]);
    }

    records.map((record, index) => {
      columnRecords[index%n].push(record);
    })

    return columnRecords;
  };

  const N = 3;

  const splitRecords = splitList(augmentedRecords, N);

  const Column = ({colNum, records}) => {
    return (
        <div className={ "column-"  + colNum}>
        {records.map((record, rowNum) => {    
          const absoluteIndex = (rowNum * N) + colNum;
          return (
            <div key={absoluteIndex}> {/* Single Card */}
              <Card record={record} onClick={() => handleOpenModal(absoluteIndex)} />
              <Modal
                isOpen={record.isOpen}
                onClose={() => handleCloseModal(absoluteIndex)}
                isFirst={absoluteIndex === 0}
                isLast={absoluteIndex === augmentedRecords.length - 1}
                onNext={() => handleOnNext(absoluteIndex)}
                onPrev={() => handleOnPrev(absoluteIndex)}
              >
                {record}
              </Modal>
            </div>)})}
        </div>  
    )
  }

  return (
    <div className="cardContainer"> {/* Containing all Cards */}
      {splitRecords.map((records, colNum) => 
        <div key={colNum}> {/* Holds a column of Cards */}
          <Column colNum={colNum} records={records}/>
        </div>
      )}
    </div>
  );
};
