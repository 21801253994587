import React, { useState, useEffect } from "react";
import { useAirTableContext } from "../../contexts/AirTableContext";
import { CardContainer } from "../Card/CardContainer";

export const Dashboard = () => {
  const { filteredRecords: originalRecords, selectedCategories } =
    useAirTableContext();

  return (
    <div className="dashboard">
        <div className="cardContainer-wrapper">
        {selectedCategories.length === 0 ? (
                <div className="Wrapper-1">
                  <div className="dashboard-heading-1">
                    A collection of <span className="count">{originalRecords.length} ways </span> clinicans have <br/> improvised in healthcare
                  </div>
                </div>
        ) : (
            <div className="Wrapper-2">
                <div className="dashboard-heading-2">Filtered Projects: </div>
            </div>
        )}
        <CardContainer />
        </div>
    </div>
  );
};
