import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const app = (
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(app);

// Learn more: https://bit.ly/CRA-vitals
// Benchmark: LCP (Largest Contentful Paint): 2.5s, FID (First Input Delay): 100ms, Cumulative Layout Shift (CLS):0.1
// reportWebVitals(console.log);