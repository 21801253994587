import { Sidebar } from "./";
import Alert from "../../components/Alert";
import { useState } from "react";

/**
 * Wrapper around authenticated routes showing the sidebar, navbar, and update section.
 */
export default function SharedLayout({ children }) {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  const message = "If you are a clinician who has made similar items, do consider contributing to the catalogue \<a href\=\"https://airtable.com/shrS8kskYFJK7YlxL\" target\=\"_blank\"> here <a/>"

  if (isVisible) {
    return (
      <div className="viewport">
        <div className="just-container">
          <Alert
            classNmae="alert"
            message={message}
            handleClose={handleClose}
          />
          <div className="layout-1">
            <Sidebar />
            <main className="body">{children}</main>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="viewport">
        <div className="just-container">
          <div className="layout-2">
            <Sidebar />
            <main className="body">{children}</main>
          </div>
        </div>
      </div>
    );
  }
}
