import { MultiSelect } from "react-multi-select-component";
import { useAirTableContext } from "../../contexts/AirTableContext";
import { Category } from "../Card";
import searchIcon from './SearchVector.svg'

export const Search = () => {
  const { allCategories, selectedCategories, setSelectedCategories } =
    useAirTableContext();

  const augmentCategories = (categories) =>
    categories.map((category, index) => ({ label: category, value: category }));
  const flattenCategories = (augmentedCategories) =>
    augmentedCategories.map((value) => value.value);

  const updateSelected = (values) => {
    setSelectedCategories(flattenCategories(values));
  };

  const customValueRenderer = (selected, _options) => {
    return (
      <div className="cardCategory cardCategory--searchBar">
        {selected.length ? (
          <div className="search-field">
            {selected.length
              ? selected.map(({ label }) => <Category category={label} />)
              : "Search and select categories"}
          </div>
        ) : (
          <div className="search-icon">
            <img src={searchIcon} alt="Search" />
          </div>
        )}
      </div>
    );
  };

  const DefaultItemRenderer = ({ checked, option, onClick, disabled }) => (
    <div className={`options item-renderer ${disabled ? "disabled" : ""}`}>
      <input
        type="checkbox"
        style={{
          accentColor: "#000000",
          borderRadius: "50%",
        }}
        onChange={onClick}
        checked={checked}
        tabIndex={-1}
        disabled={disabled}
      />
      <span>{option.label}</span>
    </div>
  );

  return (
    <MultiSelect
      options={augmentCategories(allCategories)}
      value={augmentCategories(selectedCategories)}
      onChange={updateSelected}
      labelBy={"Search and select categories"}
      valueRenderer={customValueRenderer}
      hasSelectAll={false}
      ItemRenderer={DefaultItemRenderer}
      className="multi-select"
    />
  );
};
