import { useState, useEffect } from "react";
import AirTable from "airtable";

export const useAirTable = () => {
  const API_TOKEN = "keyVyue2BwybMrVe8"; // replace with your API key
  AirTable.configure({ apiKey: API_TOKEN });
  const base = AirTable.base("apprWMvt14yCq3SC9");
  const table = base.table("tblgb5Xv4XUoxxwXD");
  const makersTable = base.table("tblZfL51GczzZFzMf");
  const hospitalsTable = base.table("tblvTxrQpKFJRl4AE");

  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const results = [];
      const makerList = {};
      const hospitalList = {};
      await makersTable
        .select()
        .all()
        .then((makers) => {
          makers.forEach((maker) => {
            makerList[maker.id] = maker.fields["Name"];
          });
        });
      await hospitalsTable
        .select()
        .all()
        .then((hospitals) => {
          hospitals.forEach((hospital) => {
            hospitalList[hospital.id] = hospital.fields["Name"];
          });
        });
      await table
        .select()
        .all()
        .then((records) => {
          var id = 1;
          records.forEach((record) => {
            const temp = record.fields;
            temp["id"] = id;
            temp["Made By"] = temp["Made By"].map((m) => makerList[m]);
            if (temp["Hospital"] !== undefined) {
              temp["Hospital"] = temp["Hospital"].map((h) => hospitalList[h]);
            }
            id += 1;
            results.push(temp);
          });
        });
      setDataList(results);
      console.log("Fetched");
    };
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 120000);
    return () => clearInterval(intervalId);
  }, []);

  return dataList;
};
