import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAirTable } from '../hooks/dataHooks'; // Import the custom hook

// Create a context
const AirTableContext = createContext();

// Create a provider component for the context
export const AirTableProvider = ({ children }) => {
    // Use the custom hook to get the data from AirTable
    const dataList = useAirTable();

    const getAllCategories = (records) => {
        const categories = records.flatMap((record) => record.Category);
        return [...new Set(categories)];
    };

    const allCategories = getAllCategories(dataList)

    const [selectedCategories, setSelectedCategories] = useState(allCategories);
    const [filteredRecords, setFilteredRecords] = useState(dataList)

    const getFilteredRecords = (records, categories) => {
        //if there are no categories selected show all results
        if (categories.length === 0) {
            return records
        }

        //if the intersection of categories in records and categories is not empty
        const filtered =  records.filter((record) =>  record.Category.filter(value => categories.includes(value)).length !== 0);
        return filtered
    };

    useEffect(() => setFilteredRecords(getFilteredRecords(dataList, selectedCategories)), [selectedCategories, dataList])

    const value = {filteredRecords, selectedCategories, setSelectedCategories, allCategories}

    // Return the context provider with the data as its value
    return (
        <AirTableContext.Provider value={value}>
            {children}
        </AirTableContext.Provider>
    );
};

// Create a custom hook to use the AirTable context
export const useAirTableContext = () => {
    // Use the useContext hook to get the data from the context
    return useContext(AirTableContext);
};