import { Search } from "../Search";

export const Sidebar = () => {
  return (
    <nav className="side-bar">
        <div className="header">
          <div className="title">
            The Olson<br></br>Catalogue
          </div>
          <div className="desc">
            Inspired by Lyla M. Olson’s work of compiling nursing improvisations for
            home care, this open-source catalogue aims to showcase how clinicians
            have improvised healthcare equipment to deliver better care to their
            patients.
          </div>
            <hr/>
        </div>
        <div className="content">
            <div className="multi-select-container">
                <div className="multi-select-title">
                    Discover Projects
                </div>
                <Search/>
            </div>
        </div>
        <div className="footer">
            <hr/>
            <div className="footer-title">
                RESOURCES
            </div>
            <div className="footer-desc">
                Interested in making but are unsure how to start? You could do so by getting these starter kits.
            </div>
        </div>
    </nav>
  );
};
